<template>
  <b-modal
    ref="modal-salary-incurred-import"
    hide-footer
    :title="'Nhập dữ liệu phát sinh lương tháng'"
    id="modal-salary-incurred-import"
    no-close-on-backdrop
    size="xl"
  >
    <div>
      <b-row>
        <b-col>
          <div class="form-group">
            <span>
              <b>Bước 1:</b> Vui lòng chuẩn bị dữ liệu mẫu từ file excel đúng
              format, hoặc có thể tải ở
              <a :href="urlExcel">đây</a>
            </span>
          </div>
        </b-col>
      </b-row>
      <span> <b>Bước 2:</b> Tiến hành Import </span>
      <b-row>
        <b-col>
          <UploadFileMulter
            v-on:send-file="getDataFromExcel"
            v-on:clear-file="clearFileExcel"
            :cmdUrl="'salary-incurreds/import-excel/'"
          />
          <span v-if="fileName"
            >Tên file: <b>{{ fileName }}</b>
          </span>
        </b-col>
      </b-row>
      <!-- End input session -->
    </div>
    <div class="mt-10" v-show="importItems.length !== 0">
      <hr
        class="hr-text"
        data-content="Danh sách phát sinh lương"
        style="font-weight: 600"
      />
      <b-table
        class="myTable"
        caption-top
        responsive
        bordered
        hover
        :fields="fields"
        :items="importItems"
        id="my-table"
        :per-page="10"
        :current-page="currentPage"
        :busy="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle ml-3"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template #cell(statusValidate)="data">
          <div v-if="!data.item.onLoading">
            <div class="d-table-cell align-middle" style="width: 10%">
              <i
                v-b-tooltip.hover
                :title="htmlGetStatusValidate(data.item, 'title')"
                :class="htmlGetStatusValidate(data.item, 'class')"
              ></i>
            </div>
            <div class="d-block align-middle pb-1 pl-2" style="width: 90%">
              <div v-for="(items, i) in data.item.errors" :key="i">
                <span class="text-danger">{{ items }}</span>
              </div>
            </div>
            <div class="d-table-cell align-middle pb-1 pl-2" style="width: 90%">
              {{ data.item.statusValidateName }}
            </div>
          </div>
          <div v-else>
            <b-spinner small variant="primary" class="mr-2"></b-spinner>
            <span class="text-primary">Đang xử lí...</span>
          </div>
        </template>
      </b-table>
      <b-col class="row mt-3">
        <b>
          Tổng số dòng:
          {{ importItems ? importItems.length : 0 }}
        </b>
      </b-col>
      <b-pagination
        v-show="importItems.length > 10"
        v-model="currentPage"
        :total-rows="rows()"
        :per-page="10"
        aria-controls="my-table"
        align="right"
      ></b-pagination>
    </div>
    <b-overlay
      v-if="!hasError && importItems.length"
      :show="onLoadingList"
      rounded
      opacity="0.6"
      class="d-inline-block mr-3"
    >
      <template #overlay>
        <div class="d-flex align-items-center">
          <b-spinner variant="light" small type="grow"></b-spinner>
        </div>
      </template>
      <b-button
        style="width: 80px"
        class="mt-6"
        :disabled="onLoadingList || !importItems.length"
        v-if="importItems.length && !createSuccess"
        variant="primary"
        size="sm"
        @click="onCreate"
      >
        <strong>Tạo mới</strong>
      </b-button>
    </b-overlay>

    <b-button
      class="mt-6"
      style="width: 80px"
      variant="secondary"
      size="sm"
      @click="hideModalExcel"
    >
      <strong>Hủy</strong>
    </b-button>
  </b-modal>
</template>
<script>
import { BASE_URL } from '@/utils/constants';
import {
  cloneDeep,
  convertPrice,
  makeToastFaile,
  makeToastSuccess,
} from '@/utils/common';
import ApiService from '@/core/services/api.service';
import UploadFileMulter from '@/view/modules/upload/UploadFileMulter';

const STATUS_VALIDATE = {
  INVALID: 0,
  VALID: 1,
  READY: 2,
  LOADING: 3,
};

const thStyleCommon = {
  textAlign: 'center',
  fontWeight: 600,
  color: '#181c32',
  width: '5%',
};

export default {
  name: 'SalaryIncurredImportExcel',
  data() {
    return {
      urlExcel:
        BASE_URL +
        'media/upload/salary-incurred/Temp_Import_SalaryIncurred_.xlsx',
      onLoadingList: false,
      currentPage: 1,
      file: null,
      importItems: [],
      isBusy: false,
      createSuccess: false,
      fileName: '',
      fields: [
        {
          key: 'salaryMonth',
          label: 'Tháng lương',
          thStyle: {
            ...thStyleCommon,
            width: '10%',
          },
          tdClass: 'align-middle',
        },
        {
          key: 'employeeCode',
          label: 'MSNV',
          thStyle: {
            ...thStyleCommon,
            width: '10%',
          },
          tdClass: 'align-middle',
        },
        {
          key: 'employeeName',
          label: 'Tên nhân viên',
          thStyle: {
            ...thStyleCommon,
            width: '20%',
          },
          tdClass: 'align-middle',
        },
        {
          key: 'typeName',
          label: 'Loại phát sinh',
          thStyle: {
            ...thStyleCommon,
            width: '5%',
          },
          tdClass: 'align-middle text-center',
        },
        {
          key: 'amount',
          label: 'Số tiền',
          thStyle: {
            ...thStyleCommon,
            width: '10%',
          },
          tdClass: 'text-right align-middle',
          formatter: (value) => {
            return convertPrice(value);
          },
        },
        {
          key: 'description',
          label: 'Diễn giải',
          thStyle: {
            ...thStyleCommon,
            width: '15%',
          },
          tdClass: 'align-middle',
        },
        {
          key: 'statusValidate',
          label: 'Trạng thái',
          thStyle: {
            ...thStyleCommon,
            width: '15%',
          },
          tdClass: 'align-middle text-center',
        },
        {
          key: 'errorMessage',
          label: 'Chi tiết kiểm tra dữ liệu',
          thStyle: {
            ...thStyleCommon,
            width: '15%',
          },
          tdClass: 'align-middle',
        },
      ],
    };
  },
  components: {
    UploadFileMulter,
  },
  computed: {
    hasError() {
      const errors = this.importItems.filter(
        (importItem) => !importItem.isValid,
      );
      return errors.length > 0;
    },
  },
  methods: {
    convertPrice,
    hideModalExcel() {
      this.$refs['modal-salary-incurred-import'].hide();
      this.clearFileExcel();
    },
    onCreate: async function () {
      const items = cloneDeep(this.importItems);
      const invalids = items.filter((item) => !item.isValid);
      if (invalids.length > 0) {
        return makeToastFaile(
          `Dữ liệu phát sinh lương hợp lệ 
          ${invalids.map((item) => item.employeeCode).join()}`,
        );
      }

      if (!this.isBusy) {
        this.onLoadingList = true;
        await ApiService.post('/salary-incurreds/list', items)
          .then((response) => {
            if (response.data.status) {
              makeToastSuccess('Nhập dữ liệu phát sinh lương thành công!');
              this.createSuccess = true;
              this.hideModalExcel();
              this.$emit('onRefresh', true);
            }
          })
          .catch((err) => {
            if (!err.response) {
              // network error
              makeToastFaile('Lỗi mạng!');
            } else {
              const message = err.response.data.message;
              makeToastFaile(message);
              this.onLoadingList = false;
            }
          });
      }
    },
    rows() {
      return this.importItems.length;
    },
    htmlGetStatusValidate(item, type) {
      let name = '';
      switch (item.statusValidate) {
        case STATUS_VALIDATE.READY: {
          name =
            type === 'class' ? 'far fa-clock text-primary' : 'Sẵn sàng tạo mới';
          break;
        }
        case STATUS_VALIDATE.VALID: {
          name =
            type === 'class'
              ? 'fas fa-check-circle text-success'
              : 'Sẵn sàng tạo mới';
          break;
        }
        default: {
          name =
            type === 'class'
              ? 'fas fa-times-circle text-danger'
              : 'Không hợp lệ';
          break;
        }
      }
      return name;
    },
    getDataFromExcel(data, fileName) {
      this.fileName = fileName;
      this.importItems = [...data.rows].map((item) => {
        const statusValidate = item.isValid
          ? STATUS_VALIDATE.READY
          : STATUS_VALIDATE.INVALID;
        return {
          ...item,
          onLoading: false,
          statusValidate: statusValidate,
        };
      });
      this.onLoadingList = false;
      this.createSuccess = false;
    },
    clearFileExcel() {
      this.importItems = [];
      this.fileName = '';
      this.file = null;
      this.onLoadingList = false;
    },
  },
};
</script>

<style lang="scss">
.modal-salary-incurred-import {
  table.b-table[aria-busy='true'] {
    opacity: 0.6;
  }
  .modal-dialog.modal-xl {
    max-width: 95vw;
  }
}
</style>
